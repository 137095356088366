<template>
  <v-container fill-height fluid class="login-container">
    <div class="top-left-logo">
      <app-logo></app-logo>
    </div>

    <v-row align="center" justify="center" style="margin-top: 5vh">
      <v-col cols="12" sm="10" md="6" lg="4">
        <v-card flat class="login-card rounded-xl mx-auto">
          <v-card-title class="text-center d-block pb-1 pt-8">
            <h1 class="sign-in-title">Sign Up</h1>
          </v-card-title>

          <v-card-subtitle class="text-center dark pb-4 pb-10">
            Let's Join us! create account with,
          </v-card-subtitle>

          <v-form lazy-validation ref="form" class="px-8 pb-6">
            <v-text-field
              prepend-inner-icon="mdi-account-circle"
              placeholder="Your Name"
              outlined
              class="mb-7 login-field"
              hide-details
              background-color="#ffffff"
              type="text"
              v-model="form.name"
            ></v-text-field>

            <v-text-field
              prepend-inner-icon="mdi-at"
              placeholder="Your Email"
              outlined
              class="mb-7 login-field"
              hide-details
              background-color="#ffffff"
              :rules="validate.required('email')"
              type="email"
              v-model="form.email"
            ></v-text-field>

            <v-text-field
              prepend-inner-icon="mdi-lock-outline"
              placeholder="Your Password"
              :rules="validate.required('password')"
              v-model="form.password"
              :type="showPassword ? 'text' : 'password'"
              outlined
              class="login-field"
              hide-details
              background-color="#ffffff"
              :append-icon="
                showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
              "
              @click:append="showPassword = !showPassword"
            ></v-text-field>

            <div class="d-flex justify-space-between align-center mt-3">
              <v-checkbox
                label="Remember me"
                color="primary"
                class="mt-0 pt-0"
                hide-details
                dense
              >
                <template v-slot:label>
                  <small class="dark">
                    I agree to Terms of
                    <router-link to="/terms-of-service">
                      Terms of Service
                    </router-link>
                    and
                    <router-link to="/privacy-policy">
                      Privacy Policy
                    </router-link>
                  </small>
                </template>
              </v-checkbox>
            </div>

            <v-btn
              block
              rounded
              color="primary"
              height="48"
              dark
              class="mt-8 mb-10 signup-btn"
              elevation="0"
              @click="$refs.form.validate() ? signUp() : null"
              :loading="loading"
            >
              Sign Up
            </v-btn>

            <div class="text-center mt-4 mb-3 account-text">
              Already have an account?
              <router-link to="/sign_in" class="signup-link"
                >Sign In</router-link
              >
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import AppLogo from "../../../components/Logo";
import FormValidation from "../../../utils/FormValidation";
import ResponseHelper from "../../../utils/ResponseHelper";

export default {
  name: "NewRegister",
  components: {
    AppLogo,
  },
  data() {
    return {
      form: {
        name: null,
      },
      validate: new FormValidation(),
      toast: new ResponseHelper(),
      loading: false,
      showPassword: false,
    };
  },
  methods: {
    ...mapActions("auth", ["sign_up"]),
    async signUp() {
      this.loading = true;
      if (this.$route.query.partner) {
        this.form.partner_refId = this.$route.query.partner;
      }
      try {
        const response = await this.sign_up(this.form);
        this.toast.sendSuccess(response);
        this.loading = false;
        await this.$router.push({ name: "Login" });
      } catch (error) {
        this.loading = false;
        this.toast.sendError(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  min-height: 100vh;
  position: relative;
}

.top-left-logo {
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 1;
}

.login-card {
  background-color: white;
  border-radius: 16px !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05) !important;
}

.sign-in-title {
  font-size: 26px;
  font-weight: bold;
  color: #111 !important;
  margin-bottom: 25px;
}

.login-field {
  ::v-deep .v-input__slot {
    min-height: 45px !important;
    border-radius: 8px;
    font-size: 15px;
  }

  ::v-deep .v-input__prepend-inner {
    margin-top: 10px !important;
    margin-right: 8px !important;
  }

  ::v-deep .v-input__append-inner {
    margin-top: 10px !important;
  }
}

.signup-btn {
  font-weight: 700 !important;
  text-transform: none;
  letter-spacing: 0;
  font-size: 16px;
  color: #ffffff !important;
}

.signup-link {
  color: #1768f3 !important;
  text-decoration: none;
  font-weight: 500;
}

.account-text {
  color: #111;
  font-size: 14px;
}

.dark {
  color: #222 !important;
}

::v-deep .v-input--selection-controls__ripple {
  display: none !important;
}

::v-deep .v-input--selection-controls .v-input__slot {
  margin-bottom: 0;
}

::v-deep .v-input--checkbox .v-label {
  font-size: 14px !important;
}
</style>
