<template>
  <div>
    <Register v-if="show === 'register'" />
    <AppSumoRegister v-if="show === 'AppSumo'" />
  </div>
</template>

<script>
import Register from "../../../components/Auth/v2/Register.vue";
import AppSumoRegister from "../../../components/Auth/AppSumoRegister.vue";
export default {
  components: {
    Register,
    AppSumoRegister,
  },
  name: "RegisterPage",
  data() {
    return {
      show: "register",
    };
  },
  created() {
    this.getRequiredComponent();
  },
  methods: {
    getRequiredComponent() {
      if (
        this.$route.query?.partner &&
        this.$route.query.partner.indexOf("appsu") > -1
      ) {
        this.show = "AppSumo";
      }
    },
  },
};
</script>

<style scoped>
.center-logo {
  display: inline-flex;
}
.bold-text {
  font-weight: 900;
}
</style>
